import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Admin from "@/views/admin/Admin.vue";
import Edit from "@/views/admin/Edit.vue";
import Create from "@/views/admin/Create.vue";
import LogIn from "@/views/LogIn.vue";
import Compare from "@/views/admin/Compare.vue";
import Form from "@/views/Form.vue";
import authToken from "@/utils/token";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    name: "login",
    component: LogIn,
    meta: {
      title: "BVI",
      guest: true,
    },
  },
  {
    path: "/",
    name: "admin-panel",
    component: Admin,
    meta: {
      title: "BVI - Dashboard",
      requiresAuth: true,
    },
  },

  {
    path: "/edit",
    name: "edit",
    component: Edit,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/create",
    name: "member-create",
    component: Create,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/compare",
    name: "compare",
    component: Compare,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/update-form",
    name: "update",
    component: Form,
    meta: {
      guest: true,
    },
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (authToken.get() == null) {
      next({
        name: "login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      const token = <string>authToken.get();
      const parsedToken = authToken.parse(token);

      if (!authToken.valid(parsedToken)) {
        const success = authToken.refresh();
        if (success) {
          next();
        } else {
          next({
            name: "login",
            params: { nextUrl: to.fullPath },
          });
        }
      }
    }
    next();
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (authToken.get() == null) {
      next();
    } else {
      next({ name: "admin-panel" });
    }
  } else {
    next();
  }
});

export default router;
