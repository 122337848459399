
































































































import Vue from "vue";
import Component from "vue-class-component";
import MemberDataTable from "@/components/MemberDataTable.vue";
import authToken from "@/utils/token";
import Banner from "@/components/banner.vue";

@Component({
  components: { Banner, MemberDataTable },
})
export default class Admin extends Vue {
  data = [];
  memberId = null;
  sendToMember = false;
  sendToAll = false;
  open = false;
  success = false;
  processing = false;

  async mounted() {
    await this.checkToken();
    await this.getJobStatus()
    const daPromise = this.$api
      .get("members", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .catch((error: any) => {
        this.open = true;
        this.success = false;
      });
    const { data: response } = await daPromise;
    this.data = response.data;

    setInterval(this.getJobStatus, 30000);
  }

  get headers() {
    return [
      { text: "Mitgliedsnummer", value: "properties.memberNr" },
      { text: "Firma", value: "properties.companyLegalForm" },
      { text: "Zuletzt geändert", value: "updatedAt", sortable: false},
      { text: "Daten gesendet", value: "hasUpdated" },
      { text: "Aktionen", value: "actions" },
    ];
  }

  async exportExcel() {
    this.checkToken();

    await this.$api
      .get("/csv/export", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        file_name: "Export",
        responseType: "blob",
      })
      .then((response: any) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Export.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error: any) => {
        this.success = false;
        this.open = true;
      });
  }

  async refreshPage() {
    const daPromise = this.$api.get("members", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const { data: response } = await daPromise;
    this.data = response.data;
  }

  async sendAll() {
    await this.checkToken();
    this.sendToAll = false;
    this.processing = true;
    await this.$api
      .get("send-all", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .finally(() => {
        this.success = true;
        this.open = true;
      })
      .catch((error: any) => {
        this.open = true;
        this.success = false;
        this.processing = false;
      });
  }

  async sendOne(id: any) {
    // TODO confirmation banner
    this.checkToken();
    await this.$api
      .post(
        "send-one",
        { memberId: id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .finally(() => {
        this.sendToMember = false;
        this.memberId = null;
        this.success = true;
        this.open = true;
      })
      .catch((error: any) => {
        this.success = false;
        this.open = true;
      });
  }

  async checkToken() {
    const token = authToken.get();

    if (token === null) {
      this.$router.push("/login");
    }
    const parsed = authToken.parse(token as string);

    if (!authToken.valid(parsed)) {
      const success = authToken.refresh();
      if (!success) {
        await this.$router.push("/login");
      }
    }
  }

  async getJobStatus() {
    await this.checkToken();
    const daPromise = this.$api
      .get("jobs", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })

    const { data: response } = await daPromise;
    this.processing = response;
  }
}
