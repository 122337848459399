
























import Vue from "vue";
import Component from "vue-class-component";
import Banner from "@/components/banner.vue";
@Component({
  components: { Banner },
})
export default class LoginForm extends Vue {
  form: {
    username: string;
    password: string;
  } = {
    username: "",
    password: "",
  };

  show = false;
  success = false;

  loginUser(): void {
    this.$api
      .post("/login", this.form)
      .then((response: any) => {
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("refresh_token", response.data.refresh_token);
      })
      .finally(() => {
        this.form.username = "";
        this.form.password = "";
        this.$router.push({ name: "admin-panel" });
      })
      .catch((error: any) => {
        this.show = true;
      });
  }
}
