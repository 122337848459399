import Vue from "vue";
import router from "@/router";

const authToken = {
  get: () => {
    return localStorage.getItem("token");
  },
  remove: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
  },
  parse: (token: string) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(atob(base64));
  },
  refresh: (): boolean => {
    Vue.prototype.$api
      .post("/login/refresh ", {
        refresh_token: localStorage.getItem("refresh_token"),
      })
      .then((response: any) => {
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("refresh_token", response.data.refresh_token);
        return true;
      })
      .catch((error: any) => {
        authToken.remove();
        return false;
      });

    return true;
  },
  valid: (token: any) => {
    return token.exp > Date.now() / 1000;
  },
};

export default authToken;
