









import Vue from "vue";
import Component from "vue-class-component";
import SubmitForm from "@/components/SubmitForm.vue";
import BackToHome from "@/views/admin/BackToHome.vue";
import authToken from "@/utils/token";

@Component({
  components: { BackToHome, SubmitForm },
})
export default class Create extends Vue {
  async onSubmit(val: any) {
    await this.$api
      .post("/members", val, {
        headers: {
          Authorization: `Bearer ${authToken.get()}`,
        },
      })
      .then(() => {
        this.$router.push({ name: "admin-panel" });
      });
  }
}
