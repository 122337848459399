









































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import authToken from "@/utils/token";

@Component
export default class SubmitForm extends Vue {
  @Prop({ default: false, type: Boolean }) isEdit?: boolean;
  @Prop({ default: false, type: Boolean }) isCreate?: boolean;
  @Prop({ default: undefined }) memberId?: any;
  @Prop({ default: undefined }) prefill?: any;
  @Prop({ default: false }) readonly?: boolean;
  step = 1;
  accepted = false;
  form: Record<string, any> = {
    memberNr: null,
    companyLegalForm: null,
    companyAddress: null,
    postal: null,
    city: null,
    companyExtra: null,
    bviContactSalutation: null,
    bviContactFirstName: null,
    bviContactLastName: null,
    bviPhone: null,
    bviMobile: null,
    bviMail: null,
    bviFax: null,
    publicContactSalutation: null,
    publicContactFirstName: null,
    publicContactLastName: null,
    publicPhone: null,
    publicFax: null,
    publicMail: null,
    publicWeb: null,
    iban: null,
    bic: null,
    creditInstitution: null,
    accountOwner: null,
    ceoInfoOtherJob: null,
    ceoInfoDateOfBirth: null,
    ceoInfoLastName: null,
    ceoInfoFirstName: null,
    internship: null,
    parttimeEmployees: null,
    fulltimeEmployees: null,
    internships: null,
    propertyManagement: null,
    garages: null,
    commercialUnits: null,
    rentalManagement: null,
    wegManagement: null,
    managementSoftware: null,
    dmsSoftware: null,
    customerPortal: null,
    insuranceCompany: null,
    insuranceClass: null,
    yearlyPayment: null,
  };

  async sendForm() {
    if (this.isCreate) {
      await this.$api.post(`/members`, this.form);
      return;
    }
  }

  mounted() {
    const token = authToken.get();

    if (this.isEdit && !this.prefill) {
      this.$api
        .get(`/members/${this.memberId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response: any) => {
          this.form = response.data.data.properties;
        });
    }

    if (this.isEdit && this.prefill) {
      this.form = this.prefill;
    }
  }
}
