












import Vue from "vue";
import Component from "vue-class-component";
import SubmitForm from "@/components/SubmitForm.vue";
import BackToHome from "@/views/admin/BackToHome.vue";

@Component({
  components: { BackToHome, SubmitForm },
})
export default class Edit extends Vue {
  memberId = "";

  async onSubmit(val: any) {
    const token = localStorage.getItem("token");
    if (val.bvhiInsurence) {
      val.insuranceCompany = "BVI";
    }
    await this.$api
      .put(`/members/${this.memberId}`, val, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        this.$router.push({ name: "admin-panel" });
      });
  }

  mounted() {
    this.memberId = this.$route.params.id;
  }
}
