








import Vue from "vue";
import Component from "vue-class-component";
import SubmitForm from "@/components/SubmitForm.vue";
import DiffForm from "@/components/DiffForm.vue";
import BackToHome from "@/views/admin/BackToHome.vue";
import authToken from "@/utils/token";
import Banner from "@/components/banner.vue";

@Component({
  components: { Banner, BackToHome, DiffForm, SubmitForm },
})
export default class Compare extends Vue {
  memberId = "";
  show = false;
  success = false;

  mounted() {
    this.checkToken();
    this.memberId = this.$route.params.id;
  }

  async onSubmit(val: any) {
    const token = localStorage.getItem("token");
    if (val.bvhiInsurence) {
      val.insuranceCompany = "BVI";
    }
    await this.$api
      .put(`/members/${this.memberId}`, val, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        this.success = true;
        this.show = true;
      })
      .finally(() => {
        this.$router.push({ name: "admin-panel" });
      });
  }

  async checkToken() {
    const token = authToken.get();

    if (token === null) {
      await this.$router.push({ name: "login" });
    }

    const parsed = authToken.parse(token as string);
    if (!authToken.valid(parsed)) {
      const success = authToken.refresh();

      if (!success) {
        await this.$router.push("/login");
      }
    }
  }
}
