<template>
  <v-alert
    v-if="success"
    v-model="show"
    dense
    text
    type="success"
    transition="scale-transition"
  >
    Erfolgreich
  </v-alert>
  <v-alert
    v-else
    v-model="show"
    dense
    text
    type="error"
    transition="scale-transition"
  >
    Fehler
  </v-alert>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, VModel, Watch } from "vue-property-decorator";
@Component()
export default class Banner extends Vue {
  @VModel({ required: true }) show;
  @Prop({ required: true }) success;

  @Watch("show")
  onShowChange() {
    setTimeout(() => {
      this.show = false;
    }, 3000);
  }
}
</script>
<style scoped></style>
