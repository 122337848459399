import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import Axios from "axios";

Vue.config.productionTip = false;

// Axios binding
Vue.prototype.$api = Axios;
Vue.prototype.$api.defaults.baseURL = process.env.VUE_APP_API_URL;

new Vue({
  el: "#app",
  template: "<App/>",
  components: { App },
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
