




































































import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Vue from "vue";

@Component
export default class MemberDataTable extends Vue {
  @Prop() data: any;
  @Prop() headers: any;
  search = "";
  shouldDelete = false;
  toDelete = null;
  toDeleteName = "";

  edit(id: string) {
    this.$router.push({ name: "edit", params: { id: id } });
  }

  diff(id: string) {
    this.$router.push({ name: "compare", params: { id: id } });
  }

  async deleteMember(id: string) {
    const token = localStorage.getItem("token");
    await this.$api
      .delete(`/members/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .finally(() => {
        this.shouldDelete = false;
        this.toDelete = null;
        this.toDeleteName = "";
      });

    this.$emit("refresh");
  }

  createMember() {
    this.$router.push({ name: "member-create" });
  }
}
