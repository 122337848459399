























import Vue from "vue";
import Component from "vue-class-component";
import authToken from "@/utils/token";
import BviLogo from "@/components/BviLogo.vue";
@Component({
  components: { BviLogo },
})
export default class App extends Vue {
  logOut() {
    this.$api
      .delete(`auth/tokens/${authToken.parse(authToken.get() as string).jti}`, {
        headers: {
          Authorization: `Bearer ${authToken.get()}`,
        },
      })
      .then(() => {
        authToken.remove();
        this.$router.push("/login");
      });
  }

  get tokenValid() {
    return authToken.get() !== null;
  }
}
